<template>
  <div class="orders">
    <div class="page-content">
      <el-tabs class="page-tab" v-model="activeName">
        <el-tab-pane label="轉檯記錄" name="seatRecord">轉檯記錄</el-tab-pane>
        <el-tab-pane label="分單記錄" name="splitOrder">分單記錄</el-tab-pane>
        <el-tab-pane label="追單記錄" name="chaseOrder">追單記錄</el-tab-pane>
        <el-tab-pane label="取消記錄" name="cancelOrder">取消記錄</el-tab-pane>
      </el-tabs>
      <SeatRecord v-if="activeName === 'seatRecord'"/>
      <SplitOrder v-if="activeName === 'splitOrder'"/>
      <ChaseOrder v-if="activeName === 'chaseOrder'"/>
      <CancelOrder v-if="activeName === 'cancelOrder'"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SeatRecord from '@/components/record/SeatRecord.vue';
import SplitOrder from '@/components/record/SplitOrder.vue';
import ChaseOrder from '@/components/record/ChaseOrder.vue';
import CancelOrder from '@/components/record/CancelOrder.vue';

export default {
  name: 'Record',
  components:{
    SeatRecord,
    SplitOrder,
    ChaseOrder,
    CancelOrder
  },
  data(){
    return {
      activeName: 'seatRecord',
    };
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header {
  display: flex;

  h2 {
    flex: 1;
  }

  .action-button {
    margin-right: 20px;
  }
}
.page-tab{
  padding-left: 20px;
  margin-bottom: 20px;
}
</style>