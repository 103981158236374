<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{ $t('檯號') }}</th>
                <th scope="col">{{ $t('日期') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>10</td>
                <td>5960968104</td>
                <td>2024/07/30 13:52:50</td>
            </tr>
            <tr>
                <td>62v</td>
                <td>2213380434</td>
                <td>2024/08/01 09:54:35</td>
            </tr>
            <tr>
                <td>60</td>
                <td>0029581052</td>
                <td>2024/08/05 11:02:36</td>
            </tr>
            <tr>
                <td>25</td>
                <td>7900450403</td>
                <td>2024/08/08 22:32:31</td>
            </tr>
            <tr>
                <td>2</td>
                <td>1312207097</td>
                <td>2024/08/10 13:56:18</td>
            </tr>
            <tr>
                <td>43</td>
                <td>0171765046</td>
                <td>2024/08/13 15:12:37</td>
            </tr>
            <tr>
                <td>33</td>
                <td>8484787592</td>
                <td>2024/08/20 21:58:23</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SplitOrder',
    computed: mapState({
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
</style>