<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{ $t('檯號') }}</th>
                <th scope="col">{{ $t('日期') }}</th>
                <th scope="col">{{ $t('檢視帳單') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>8</td>
                <td>2024/07/06 09:46:34</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
                <td>25</td>
                <td>2024/07/07 21:40:32</td>
                <td>$110</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
                <td>61</td>
                <td>2024/07/09 15:06:49</td>
                <td>$160</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
                <td>9</td>
                <td>2024/07/19 11:10:10</td>
                <td>$130</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
                <td>7</td>
                <td>2024/07/24 12:24:15</td>
                <td>$95</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
                <td>10</td>
                <td>2024/07/30 13:52:50</td>
                <td>$100</td>
                <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('檢視') }}
                  </a>
                </div>
              </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SplitOrder',
    computed: mapState({
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
</style>