<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{ $t('檯號') }}</th>
                <th scope="col">{{ $t('日期') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>2024/07/09 09:46:34</td>
            </tr>
            <tr>
                <td>7</td>
                <td>2024/07/09 21:40:32</td>
            </tr>
            <tr>
                <td>62</td>
                <td>2024/07/11 06:06:32</td>
            </tr>
            <tr>
                <td>24</td>
                <td>2024/07/19 21:19:07</td>
            </tr>
            <tr>
                <td>51</td>
                <td>2024/08/03 12:24:15</td>
            </tr>
            <tr>
                <td>7</td>
                <td>2024/08/09 13:52:50</td>
            </tr>
            <tr>
                <td>61v</td>
                <td>2024/08/11 09:54:35</td>
            </tr>
            <tr>
                <td>75</td>
                <td>2024/08/11 11:02:36</td>
            </tr>
            <tr>
                <td>38</td>
                <td>2024/08/12 22:32:31</td>
            </tr>
            <tr>
                <td>33</td>
                <td>2024/08/13 13:56:18</td>
            </tr>
            <tr>
                <td>23</td>
                <td>2024/08/13 15:12:37=</td>
            </tr>
            <tr>
                <td>43</td>
                <td>2024/08/13 21:58:23</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SeatRecord',
    computed: mapState({
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
</style>